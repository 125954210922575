import { Switch, Route, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Home from '../pages/Home';
import Login from '../pages/Login';
import Menu from '../pages/Menu';
import Channel from '../pages/Channel';
import RestrictChannel from '../pages/RestrictChannel';
import DetectWifi from '../pages/DetectWifi';
import NotFound404 from '../pages/NotFound404';
import AudioWifi from '../pages/AudioWifi';
import Exit from '../pages/Exit';
import { useState, useEffect } from 'react';
import PrivateRoute from '../components/PrivateRoute';
import data from '../components/storage';
import AppBuscaEvento from '../components/AppBuscaEvento';
import AppBuscaSessao from '../components/AppBuscaSessao';

export default function Routes() {
  const pass = useSelector((state) => state.pass);
  const app_busca_evento = useSelector((state) => state.app_busca_evento);
  const app_busca_sessao = useSelector((state) => state.app_busca_sessao);
  const language = useSelector((state) => state.language);
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [ignore, setIgnore] = useState(false);

  useEffect(() => {
    (async () => {
      let getData = await sessionStorage.getItem('data');
      if (!getData) {
        await sessionStorage.setItem('data', data);
      } else {
        getData = JSON.parse(getData);
        dispatch({ type: 'add_app_busca_evento', json: getData.app_busca_evento })
        dispatch({ type: 'add_app_busca_sessao', json: getData.app_busca_sessao })
        dispatch({ type: 'add_pass', loginPass: getData.pass})
        dispatch({ type: 'change_auth', current: getData.auth })
      }

      const pooling = setInterval(() => { 
        (async () => {
            let getAuth = await sessionStorage.getItem('data');
            getAuth = await JSON.parse(getAuth);
            if (getAuth.auth) {
              const poolRequestEvento = await AppBuscaEvento(getAuth.pass);
              dispatch({ type: 'add_app_busca_evento', json: poolRequestEvento })
            }            
        })()  
      }, 30000);



    })();
  }, [])

  useEffect(() => {
    if (ignore) {
      let newData = data;
      newData.app_busca_evento = app_busca_evento;
      newData.app_busca_sessao = app_busca_sessao;
      newData.pass = pass;
      newData.auth = auth;
      sessionStorage.setItem('data', JSON.stringify(newData));

    }

    setIgnore(true);
  }, [app_busca_evento, app_busca_sessao, auth, pass])

  // ------------- LANGUAGE

  useEffect(() => {
    async function getLanguage() {
      const getStorageLanguage = await localStorage.getItem('language');
      if (!getStorageLanguage) {
        localStorage.setItem('language', 'pt');
      } else {
        dispatch({
          type: 'change_language',
          language: getStorageLanguage,
        })
      }
    }
    getLanguage();
  }, []);

  useEffect(() => {
    async function changeLanguage() {
      const storageLanguage = await localStorage.getItem('language');
      if (storageLanguage) {
        await localStorage.setItem('language', language);
      }
    }

    changeLanguage();

  }, [language]);


  // -------------

  return (
    <Switch>
      <Route exact path="/" component={Login} />
      <PrivateRoute exact path="/home" component={Home} />
      <PrivateRoute exact path="/rooms" component={Menu} />
      <PrivateRoute exact path="/channel" component={Channel} />
      <PrivateRoute exact path="/restrict-channel" component={RestrictChannel} />
      <PrivateRoute exact path="/wifi" component={DetectWifi} />
      <PrivateRoute exact path="/audio-wifi" component={AudioWifi} />
      <PrivateRoute exact path="/exit" component={Exit} />
      <Route exact path="*" component={NotFound404} />
    </Switch>
  )
}