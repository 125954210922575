import Header from '../../components/Header';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BackBTN from '../../components/BackBTN';
import { Container, ContainerBox, Title, Micro, Logout, Channels, Mute, AudioMenu } from './styles';
import { useHistory } from 'react-router-dom';
import Microphone from '../../assets/images/giphy.jpg';
import LogoutIcon from '../../assets/images/logout.svg';
import ChannelsIcon from '../../assets/images/backw.svg';
import MuteIconOn from '../../assets/images/sound-on.svg';
import MuteIconOff from '../../assets/images/sound-off.svg';
import translate from '../../Language/audio';
import AppBuscaSessao from '../../components/AppBuscaSessao';
import AppLog from '../../components/AppLog';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

let pc = null;

export default function AudioWifi() {
  const history = useHistory();
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language);
  const app_busca_evento = useSelector((state) => state.app_busca_evento);
  const app_busca_sessao = useSelector((state) => state.app_busca_sessao);
  const [selectedRoom, setSelectedRoom] = useState({});
  const [selectedChannel, setSelectedChannel] = useState({});
  const [status, setStatus] = useState(0);
  const [mudo, setMudo] = useState(false);
  let audioWin;
  let statusPt = ['Conectando', 'Erro ao conectar', 'Ao vivo', 'Em espera'];
  let statusEn = ['Connecting', 'Error to connect', 'Live', 'On hold'];
  
  async function sair() {
    await sessionStorage.removeItem('selectedRoom');
    await sessionStorage.removeItem('selectedChannel');
    await sessionStorage.removeItem('data');
    history.push('./exit');
  }

  useEffect(() => {
    const interval = setInterval(async () => {
      let uniqueID = await localStorage.getItem('uniqueID');
      const getSelectedChannel = await sessionStorage.getItem('selectedChannel');
      await AppLog(uniqueID, JSON.parse(getSelectedChannel).canal_id);
    }, 60000);
    return () => clearInterval(interval);
  
  }, [])

  useEffect(() => {
    (async function() {
      const getSelectedRoom = await sessionStorage.getItem('selectedRoom');
      const getSelectedChannel = await sessionStorage.getItem('selectedChannel');
      setSelectedRoom(JSON.parse(getSelectedRoom));
      setSelectedChannel(JSON.parse(getSelectedChannel));
      setMudo(false);
      audioWin = document.getElementsByTagName('audio')[0];
      console.log(document.getElementsByTagName('audio'));
      console.log(audioWin);

      const constraints = window.constraints = {
        audio: true,
        video: false
      };
      

      // Verificar SO. Se não for Linux, Windows ou Android
      // deve-se requisitar permissão.
      let platform = window.navigator.platform;
      platform = platform.toLowerCase();

      if (platform.includes('Linux') || platform.includes('linux')
          || platform.includes('Android') || platform.includes('android')
          || platform.includes('Win') || platform.includes('win')
          || platform.includes('Windows') || platform.includes('windows')) {
        openConnectionMillicast();
      } else {
        window.navigator.mediaDevices.getUserMedia(constraints)
          .then(openConnectionMillicast)
          .catch((err) => {setStatus(1);});
      }

      let uniqueID = await localStorage.getItem('uniqueID');
      if (!uniqueID) {
        var randomString = require('random-string');
        uniqueID = randomString({length: 40});
        await localStorage.setItem('uniqueID', uniqueID);
      }

      /*const interval = setInterval(() => {
        AppLog(uniqueID, JSON.parse(getSelectedChannel).canal_id);
        console.log("Teste");
      }, 10000);
      return () => clearInterval(interval);*/
      
    })()
  }, [])

  useEffect(() => {
    (async function() {
      let getSelectedChannel = await sessionStorage.getItem('selectedChannel');
      getSelectedChannel = await JSON.parse(getSelectedChannel);
      let getSelectedRoom = await sessionStorage.getItem('selectedRoom');
      getSelectedRoom = await JSON.parse(getSelectedRoom);

      //--- Verifica o estado CANAL ---
      const response = await AppBuscaSessao(getSelectedRoom.sessao_id);
      for(var aux = 0; aux < response.canais.length; aux++) {
        if (response.canais[aux].canal_id == getSelectedChannel.canal_id) {
          if (response.canais[aux].canal_estado !== 1) {
            if (status == 2 || status == 3) {
              pc.close();
              pc = null;
            }
            return history.push('./channel')
          }    
        }
      }

      //--- Verifica o estado da SESSÃO ---
      
      if (response.sessao.sessao_estado === 2) {
        if (status == 2)
          setStatus(3);
      } else if (response.sessao.sessao_estado === 3 && status == 3) {
        setStatus(2);
      } else if (response.sessao.sessao_estado !== 3) {
        if (status == 2 || status == 3) {
          pc.close();
          pc = null;
        }
        history.push('./rooms');
      }
      
    })()
  }, [app_busca_evento, app_busca_sessao])

  async function openConnectionMillicast(stream) {
    let getSelectedChannel = await sessionStorage.getItem('selectedChannel');
    let url_millicast = JSON.parse(getSelectedChannel).canal_URL_broadcast;
    if (url_millicast == "" || url_millicast == undefined) {
      setStatus(1);
      return;
    }
    //console.log(url_millicast);
    let streamName = url_millicast.split('/')[4]; //Replace with your stream name.
    let accountId = url_millicast.split('/')[3].split('=')[1]; //Replace with your account id.
    let URL; //Websocket URL path.
    let jwt; //Authenticated API token.
    var xhr = new XMLHttpRequest();
    xhr.open(
      'POST',
      'https://director.millicast.com/api/director/subscribe',
      true,
    );
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.onreadystatechange = function () {
      if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
        var js = JSON.parse(xhr.responseText);
        URL = js.data.urls[0];
        jwt = js.data.jwt;
        connectMillicast(URL, jwt, streamName);
      } else if(this.status === 400) {
        setStatus(1);
      }
    };
    xhr.send(
      JSON.stringify({
        streamAccountId: accountId,
        streamName: streamName,
        unauthorizedSubscribe: true,
      }),
    );
  }

  async function connectMillicast(URL, jwt, streamName) {
    
    let iceServers; // STUN/TURN server paths to better guarantee our connection.
    let conf = {
      iceServers: iceServers,
      rtcpMuxPolicy: 'require',
      bundlePolicy: 'max-bundle'
    };
    
    pc = new RTCPeerConnection(conf);
    pc.addTransceiver('audio');
    pc.ontrack = function(event) {
      //Play it
      if(audioWin) {
        audioWin.srcObject = event.streams[0];
        audioWin.controls = false;
        audioWin.autoplay = true;
        audioWin.muted = false;
      }
    };
    //connect with Websockets for handshake to media server.
    let ws = new WebSocket(URL + '?token=' + jwt);
    ws.onopen = function () {
      
      //create a WebRTC offer to send to the media server
      let offer = pc
      .createOffer({
        offerToReceiveAudio: true,
        offerToReceiveVideo: false
      })
      .then((desc) => {
        pc.setLocalDescription(desc)
          .then(() => {
            //console.log("Set Local OK");
            ///set required information for media server.
            let data = {
              name: streamName,
              sdp: desc.sdp,
              codec: 'h264',
            };
            //create payload
            let payload = {
              type: 'cmd',
              name: 'view',
              data: data,
            };
            ws.send(JSON.stringify(payload));
          })
          .catch((e) => {
            console.log('setLocalDescription failed: ', e);
            setStatus(1);
          });
      })
      .catch((e) => {
        console.log('createOffer Failed: ', e);
        setStatus(1);
      });
    };

    ws.addEventListener('message', async (evt) => {
      let msg = JSON.parse(evt.data);
      switch (msg.type) {
        //Handle counter response coming from the Media Server.
        case 'response':
          let data = msg.data;
          let answer = new RTCSessionDescription({
            type: 'answer',
            //sdp: data.sdp + 'a=x-google-flag:conference\r\n',
            sdp: data.sdp
          });
          pc.setRemoteDescription(answer)
          .then(() => {
           setStatus(2);
           handleMute();
          })
          .catch((e) => {
            console.log('setLocalDescription failed: ', e);
          });
          break;
      }
    });

  }


  //const [estatoAtual, alterarEstado] = useState('estado inicial do state');
  //exemplo → [count, setCount] = useState(0);
  // para adicionar 1 ao estado atual → setCount((state) => state + 1);

  async function handleLogout() {
    if (status == 0)
      return;
    confirmAlert({
      title: language == 'pt' ? 'Sair' : 'Logout',
      message: language == 'pt' ? 'Deseja sair?' : 'Do you want to exit?',
      buttons: [
        {
          label: language == 'pt' ? 'Sim' : 'Yes',
          onClick: () => sair()
        },
        {
          label: language == 'pt' ? 'Não' : 'No',
          onClick: () => {}
        }
      ]
    });
  }

  async function handleChannels() {
    if (status == 0)
      return;
    //console.log('handleChannels');
    await sessionStorage.removeItem('selectedChannel');
    await sessionStorage.removeItem('data');
    if (status == 2 || status == 3) {
      pc.close();
      pc = null;
    }
    history.push('./channel');
  }

  function handleMute() {
    //console.log('handleMute');
    setMudo(!mudo);
    audioWin = document.getElementsByTagName('audio')[0];
    audioWin.muted = mudo;
  }

  return (
    <Container>
      <Header />
      <BackBTN handleBackPage={handleChannels} language={language} />
      <ContainerBox>
        <Title>{translate[language].title}</Title>   
        <div><h3>{selectedRoom.sala_nome}</h3></div>
        <div><h3>{language == 'pt' ? selectedChannel.canal_nome : 
            selectedChannel.canal_ingles}</h3></div>
        <div><h3>{language == 'pt' ? statusPt[status] : statusEn[status]}</h3></div>
        <Micro><img src={Microphone} alt="Microphone" /></Micro>
        <audio autoplay></audio>
        <AudioMenu>
          <Logout onClick={handleLogout}><img src={LogoutIcon} alt="Logout" /></Logout>
          <Channels onClick={handleChannels}><img src={ChannelsIcon} alt="Channels" /></Channels>
          <Mute onClick={handleMute}><img src={mudo ? MuteIconOn : MuteIconOff} alt="Mute" /></Mute>
        </AudioMenu>
        
      </ContainerBox>
    </Container>
  )
}