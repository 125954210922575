module.exports = {
  pt: {
    Menu: {
     title: 'Selecione uma sala',
     restrict: 'restrita',
     notFound: 'Não há programação para o dia de hoje.',
    }
  },
  en: {
    Menu: {
      title: 'Select a room',
      restrict: 'restrict',
      notFound: 'There is no schedule for today.',
    }
  }
}