import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: #eee;
  display: flex;
  align-items: center;
  padding: 150px;
  flex-direction: column;

  @keyframes anima {
    from {
      transform: translateX(-26px);
      opacity: 0;
    }; 
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @media (max-width: 1350px) {
    padding: 100px;
  }

  @media (max-width: 1000px) {
    padding: 100px 80px;
  }

  @media (max-width: 800px) {
    padding: 100px 40px;
  }

  @media (max-width: 600px) {
    padding: 100px 10px;
  }

`;

export const ContainerBox = styled.div`
    width: 100%;
    min-height: 50vh;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;

`;


export const Title = styled.div`
  font-size: 26px;
  font-weight: bolder;
  align-self: flex-start;
  margin: 0 0 50px 200px;
  letter-spacing: 2px;

  @media (max-width: 1350px) {
    margin: 0 0 50px 150px;
  }

  @media (max-width: 1000px) {
    margin: 0 0 50px 100px;
  }

  @media (max-width: 800px) {
    margin: 0 0 50px 50px;
  }

  @media (max-width: 600px) {
    margin: 0 0 50px 10px;
    font-size: 20px;
  }

`;

export const Item = styled.div`
  animation-name: anima;
  animation-duration: 0.4s;
  animation-timing-function: ease-out;
  position: relative;
  width: 96%;
  max-width: 700px;
  height: 80px;
  border: 2px solid #c1c1c180;
  border-radius: 6px;
  margin: 14px 0; 
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 300ms ease-out 0s;

  :hover {
    box-shadow: 0px 0px 14px 5px #00000030;
    max-width: 710px;
  }

  @media (max-width: 600px) {
    height: 70px;
    margin: 8px 0; 
  }
`;

export const LockIcon = styled.div`
  height: 100%;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1350px) {
    width: 100px;
  }

  @media (max-width: 1000px) {
    width: 80px;
  }

  @media (max-width: 800px) {
    width: 60px;
  }

  @media (max-width: 600px) {
    width: 40px;
    img {
      width: 20px;
    }
  }
  
`;

export const TextItem = styled.div``;

export const RoomName = styled.div`
  font-size: 16px;
  font-weight: bolder;
  letter-spacing: 1px;
  color: #010f5c;


  @media (max-width: 600px) {
    font-size: 13px;
  }
`;

export const NextIcon = styled.div`
  width: 24px;
  height: 26px;
  transform: translateY(2px);
  position: absolute;
  right: 10px;

  @media (max-width: 600px) {
    width: 14px;
    height: 14px; 
    right: 0px;
    transform: translateY(0px);
  }

`;

export const IsRestrict = styled.div`
  background-color: #a21111;
  margin-left: 50px;
  color: #fff;
  padding: 2px 10px;
  border-radius: 4px;
  font-size: 13px;
  font-variant: small-caps;
  letter-spacing: 1px;

  @media (max-width: 600px) {
    margin-left: 10px;
    font-size: 11px;
  }


`;

export const Estado = styled.div`
  position: absolute;
  right: 100px;
  font-size: 14px;
  color: #00000080;
  font-weight: bolder;

  @media (max-width: 600px) {
    right: 20px;
    font-size: 10px;
  }
`;

export const NotFound = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-weight: bolder;
    letter-spacing: 1.2px;
    font-size: 20px;
    color: #000000;
    img {
      display: block;
      margin: 20px 0;
    }

    @media (max-width: 1350px) {
      font-size: 14px;

      img {
        width: 50px;
      }
    }
  
`;