module.exports = {
  pt: {
    Home: {
      nextButton: 'próximo',
      logoutButton: 'sair',
      nomeEvento: 'Nome do evento',
      localEvento: 'Local do evento',
      dataInicio: 'Data de início',
      dataFinal: 'Data final',
    }
  },
  en: {
    Home: {
      nextButton: 'next',
      logoutButton: 'Logout',
      nomeEvento: 'Event name',
      localEvento: 'Event location',
      dataInicio: 'Start date',
      dataFinal: 'End date',
    }
  }
}