module.exports = {
  pt: {
    login: {
      placeholder: 'Informe o PIN',
      loginButton: 'entrar',
      notfound: 'Nenhum evento encontrado, verifique o PIN e tente novamente!',
      pinerror: 'Este PIN está sendo utilizado. Por favor, tente novamente mais tarde ou contacte a organização do evento.',
      error: 'deu erro',
      pt: 'Português',
      en: 'Inglês',
    }
  },
  en: {
    login: {
      placeholder: 'Enter the PIN',
      loginButton: 'sign in',
      notfound: 'No events were found, check the PIN and try again!',
      pinerror: 'This PIN is being used. Please try again later or contact the event organizer.',
      error: '',
      pt: 'Portuguese',
      en: 'English',
    }
  }
}